import { AddressBookPhysician } from "@/lib/types/addressBook";
import { EMRPhysiciansSummaryResponse } from "@/lib/types/physician";
import { baseAPIClient } from "./BaseAPIClient";

// const getPhysicians = (callback: Function) => {
//   callback(baseAPIClient.post("/physician/get"));
// };

// const getPhysiciansSummary = async (): Promise<EMRPhysiciansSummaryResponse> => {
//   const { data } = await baseAPIClient.post("/physician/get.summary");
//   return data;
// };

const getPhysiciansElastic = (query: string, callback: Function) => {
  callback(baseAPIClient.post("/physician/search", { query }));
};

// const searchPhysiciansById = (id: number, callback: Function) => {
//   callback(baseAPIClient.post("/physician/get/by.id", { id }));
// };

// const createPhysician = (physician: EMRPhysicianCreationClass) => {
//   return BaseAPIClient
//     .post("/physician/create", physician)
//     .then((res: AxiosResponse) => {
//       return res.data;
//     })
//     .catch((error: AxiosError) => {
//       return error.response?.data;
//     });
// };

const getAddressBookPhysicians = async () => {
  const resp = await baseAPIClient.get("/physician/");

  return resp;
};

const getAddressBookPhysician = async (id: string) => {
  const resp = await baseAPIClient.get(`/physician/${id}`);

  return resp;
};

const updateAddressBookPhysician = (physician: AddressBookPhysician, callback: Function) => {
  callback(baseAPIClient.post("/physician/update", physician));
};

const createAddressBookPhysician = (physician: AddressBookPhysician, callback: Function) => {
  callback(baseAPIClient.post("/physician/create", physician));
};

const physicianAPIClient = {
  // getPhysicians,
  // getPhysiciansSummary,
  getPhysiciansElastic,
  // searchPhysiciansById,
  // createPhysician,
  getAddressBookPhysicians,
  getAddressBookPhysician,
  updateAddressBookPhysician,
  createAddressBookPhysician,
};

export default physicianAPIClient;
