import { APIPatientMerge, EMRPatient, PatientAlternateContactInfo } from "@/lib/types/patient";
import customFetch from "./customFetch";
import { LesionItemType } from "@/lib/types/aiVerification";

const getPatientDataById = async (patientId: number): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient/patientId/${patientId}`);
  return patient;
};

const updatePatient = async (patientData: EMRPatient): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient`, { body: patientData, method: "PUT" });
  return patient;
};

const mergePatient = async (mergeData: APIPatientMerge, patientMergedId: number): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient/patientId/${patientMergedId}/action/merge`, { body: mergeData, method: "POST" });
  return patient;
};

const getPatientLesionHistory = async (patientId: number): Promise<LesionItemType[]> => {
  const { patientLesionHistories } = await customFetch(`/patient/patientId/${patientId}/lesions`);
  return patientLesionHistories;
};

const archivePatientLesionHistory = async (noteId: number): Promise<string> => {
  const { message } = await customFetch(`/patient/lesion/${noteId}/action/archive`, { method: "PATCH" });
  return message;
};

const getPatientAlternateContactsInfo = async (patientId: number): Promise<PatientAlternateContactInfo[]> => {
  const { patientAlternateContacts } = await customFetch(`/patient/patientId/${patientId}/altContacts/info`);
  return patientAlternateContacts;
};

const patientAPI = {
  getPatientDataById,
  updatePatient,
  getPatientLesionHistory,
  archivePatientLesionHistory,
  getPatientAlternateContactsInfo,
  mergePatient,
};

export default patientAPI;
