import { AxiosError, AxiosResponse } from "axios";
import {
  APIPatientAlternateContacts,
  EMRPatient,
  EMRPatientsResponse,
  PatientIdentityVerificationResponse,
  UnlockIdentityVerificationResponse,
  ValidatePatientNumberResponse,
} from "@/lib/types/patient";
import { LesionItemType, LesionTypes, OtherLesionType } from "@/lib/types/aiVerification";
import { baseAPIClient } from "./BaseAPIClient";
import { PatientFileType } from "@/lib/types/encounterNote";

type Note = {
  body: string;
  subject: string;
};

type lesionInfoData = {
  id: number;
  doctorAnalysis: LesionTypes | undefined;
  reviewStatus: string;
  isAiCorrect: any;
  urgencyRating: any;
  doctorAnalysisOther: OtherLesionType[] | undefined;
};

const updatePatient = async (patient: EMRPatient) => {
  return await baseAPIClient.put("/patient", patient);
};

const archivePatient = (patientId: number) => {
  return baseAPIClient.post(`/patient/patientId/${patientId}/action/archive`);
};

const createPatientNote = (note: Note, patientId: number) => {
  return baseAPIClient.post(`/patient/patientId/${patientId}/notes`, note);
};

const getPatientNotes = (patientId: number, callback: Function) => {
  callback(baseAPIClient.get(`/patient/patientId/${patientId}/notes`));
};

const createPatient = async (patient: EMRPatient) => {
  return await baseAPIClient.post("/patient/", patient);
};

// TODO: remove any type, this function does not seem to be used anywhere either
const createNewPatients = (patient: any) => {
  if (patient.sex) {
    if (patient.sex === "MALE") {
      patient.sex = 0;
    }
    else if (patient.sex === "FEMALE") {
      patient.sex = 1;
    }
    else {
      patient.sex = 2;
    }
  }
  return baseAPIClient
    .post("/patient/create/from.app", patient)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      return error.response?.data;
    });
};

const getPatient = async (patientId: number) => {
  return baseAPIClient.get(`/patient/patientId/${patientId}`);
};

const getPatientByHCN = async (patientHCN: string, patientVC: string) => {
  return baseAPIClient.get(`/patient/patientHCN/${patientHCN}/patientVC/${patientVC}`);
};

const getAllPatientLesionHistories = async () => {
  return baseAPIClient.get("/patient/lesion");
};

const getPatientLesionHistory = async (patientId: number): Promise<LesionItemType[]> => {
  const res = await baseAPIClient.get(`/patient/patientId/${patientId}/lesions`);
  return res.data.patientLesionHistories;
};

const getPatientAlternateContacts = async (patientId: number) => {
  const res = await baseAPIClient.get(`/patient/patientId/${patientId}/altContacts`);
  return res;
};

const deletePatientAlternateContact = async (relationId: number) => {
  const res = await baseAPIClient.delete(`/patient/altContact/${relationId}`);
  return res;
};

const getPatientContactInfo = async (relationId: number) => {
  const res = await baseAPIClient.get(`/patient/altContact/${relationId}`);
  return res;
};

const deletePatientAlternateContacts = async (relationIds: number[]) => {
  const res = await baseAPIClient.post(`/patient/altContact/delete-many`, relationIds);
  return res;
};

const updatePatientAlternateContacts = async (patientId: number, contacts: APIPatientAlternateContacts) => {
  const res = await baseAPIClient.put(`/patient/patientId/${patientId}/altContacts`, contacts);
  return res;
};

const getPatientIdentityVerificationInfo = async (patientId: number): Promise<PatientIdentityVerificationResponse> => {
  const { data } = await baseAPIClient.get(`/patient/identityVerification/${patientId}`);
  return data;
};

const unlockPatientIdentityVerification = async (patientId: number): Promise<UnlockIdentityVerificationResponse> => {
  const { data } = await baseAPIClient.patch(`/patient/identityVerification/${patientId}`);
  return data;
};

const updatePatientLesionHistory = (lesionInfo: lesionInfoData) => {
  return baseAPIClient
    .put("/patient/lesion", lesionInfo)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
};

const updatePatientLesionHistoryIsJunk = (id: number, lesionInfo: { isJunk: boolean }) => {
  return baseAPIClient.put(`/patient/lesion/${id}/action/set-junk`, lesionInfo);
};

const listPatientFiles = async (patientId: number): Promise<PatientFileType[]> => {
  const ret = await baseAPIClient.get(`/patient/patientId/${patientId}/files`);
  return ret.data.files;
};

const getPatients = async (): Promise<EMRPatientsResponse> => {
  const { data } = await baseAPIClient.get("/patient/");
  return data;
};

const fuzzySearchPatient = (data: { query: string }, callback: Function) => {
  callback(baseAPIClient.post("/patient/search", data));
};

const writePatientFile = (writePatientFileForm: FormData) => {
  return baseAPIClient
    .post("/patient/files", writePatientFileForm, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      return error.response?.data;
    });
};

const archivePatientLesionHistory = async (id: number) => {
  const { data } = await baseAPIClient.patch(`/patient/lesion/${id}/action/archive`);
  return data;
};

const validatePatientNumber = async (number: string): Promise<ValidatePatientNumberResponse> => {
  const { data } = await baseAPIClient.post("/patient/validateNumber", {
    number,
  });
  return data;
};

const patientAPIClient = {
  updatePatient,
  archivePatient,
  createPatientNote,
  getPatientNotes,
  createPatient,
  createNewPatients,
  getPatient,
  getPatientByHCN,
  getAllPatientLesionHistories,
  getPatientLesionHistory,
  getPatientAlternateContacts,
  deletePatientAlternateContact,
  getPatientContactInfo,
  deletePatientAlternateContacts,
  updatePatientAlternateContacts,
  getPatientIdentityVerificationInfo,
  unlockPatientIdentityVerification,
  updatePatientLesionHistory,
  updatePatientLesionHistoryIsJunk,
  listPatientFiles,
  getPatients,
  fuzzySearchPatient,
  writePatientFile,
  archivePatientLesionHistory,
  validatePatientNumber,
};

export default patientAPIClient;
